$(function(){
  var $header = $("#myModal .modal-header"),
  $body = $("#myModal .modal-body"),
  $footer = $("#myModal .modal-footer");

  var targetElementId = targetElementHeader = targetElementBody = "";
  $("#modal-btn-container").on('click', '.box-content > img', function(event){
     targetElementId = event.target.id;
     targetElementHeader = $(`div[dataTarget="${targetElementId}"] .modal-title`).html();
     targetElementBody =  $(`div[dataTarget="${targetElementId}"] .modalBody`).html();

     $header.html(targetElementHeader);
     $body.html(targetElementBody);
     var footerContent = $(`#${targetElementId} .modalFooter`).html();
     $footer.html(footerContent);
  });
})
