$(function(){
  $("body").on("click", ".update_address_button", function(){
    submit_address('address_form');
  });
});

function submit_address(form_address) {
  reason = document.getElementById('reason');

  if(reason) {
    var value = reason.value;

    if(value.length < 1){
      alert('Please, enter a reason.');
      return false;
    }  
  }

  document.getElementById(form_address).submit();
}

function set_via_payment(){
  if(document.getElementById('payment_request_pay_via').value === ''){
    close_div('div_bacs');
    close_div('div_cheque');
  }
  else if(document.getElementById('payment_request_pay_via').value === "bacs"
    || document.getElementById('payment_request_pay_via').value === "direct_debit"
    || document.getElementById('payment_request_pay_via').value === "standing_order"
    ){
      close_div('div_cheque');
      open_div('div_bacs');
  }
  else if(document.getElementById('payment_request_pay_via').value === "cheque"){
      open_div('div_cheque');
      close_div('div_bacs');
  }
}

ajax_flash_messages = {
    init: function(klass, msg) {
        console.log(klass)
        var me = this
        jQuery("#flash-messages").html('')
        jQuery("#flash-messages").html('<div class="alert alert-'+klass+'"><button id="close_flash" type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button><div id="flash_' + klass +'">' + msg + '</div></div>')
        jQuery("#close_flash").bind("click", me.close)
        jQuery("#flash-messages").fadeIn("5000")
    },

    close: function(){
        jQuery("#flash-messages").hide()
        jQuery("#flash-messages").html('')
    }
}
